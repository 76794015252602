import React from 'react'
import { MasterAnchor } from 'atoms/links'

export default function Banner() {
  return (
    <section className="home-banner  justify-center blade-top-padding-lg blade-bottom-padding-sm flex items-center">
      <div className="grid pt-5 md:pt-0 place-content-center w-containe gap-3 md:gap-4  lg:gap-6  h-full">
        <h2 className="title bg-clip-text font-semibold text-center text-transparent md:block hidden">
          Pioneering Electro-Mechanical Solutions{' '}
          <br className="md:block hidden" />
          with Unwavering Precision
        </h2>
        <h3 className="title bg-clip-text font-semibold text-center text-transparent  md:hidden block px-3 leading-snug">
          Pioneering Electro-Mechanical Solutions{' '}
          <br className="md:block hidden" />
          with Unwavering Precision
        </h3>

        <h6 className="text-center text-white text-opacity-80 font-medium leading-snug">
          Welcome to Shashi Engineering
        </h6>
        <div className="grid place-content-center  pt-4 md:pt-5 lg:pt-7">
          <MasterAnchor
            href="/contact"
            text="Get a quote now"
            size="large"
            color="light"
          />
        </div>
      </div>
    </section>
  )
}
