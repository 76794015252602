import React from 'react'

interface Pointer {
  title: string
  desc: string
}
interface Props {
  data: {
    title: string
    desc: string
    coverImage: string
    pointers: Array<Pointer>
  }
}

export default function Features({ data }: Props) {
  const { title, coverImage, pointers, desc } = data
  return (
    <div>
      <div className="grid grid-cols-2">
        <div className="bg-slate-200 blade-bottom-padding">
          <div className="sticky flex flex-col gap-4 md:gap-6 xl:gap-12 2xl:gap-16 max-w-2xl w-10/12 mx-auto top-20 blade-top-padding blade-bottom-padding">
            <h3 className="text-slate-900 leading-tight font-medium">
              {title}
            </h3>
            <h6 className="text-slate-600">{desc}</h6>
          </div>
        </div>
        <div className=" blade-top-padding blade-bottom-padding bg-slate-100">
          <div className="flex flex-col gap-10 max-w-2xl  w-10/12 mx-auto ">
            {pointers.map((elem, index) => {
              const key = `${index}`
              return <Card data={elem} index={index + 1} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function Card({ data, index }: { index: number; data: Pointer }) {
  const { title, desc } = data
  return (
    <article className="bg-white border-slate-200 border-1 border-solid rounded-lg gap-6 lg:gap-8 2xl:p-10 ">
      <div className="grid  gap-3 ">
        <h1 className="font-extrabold text-slate-400 italics">
          {' '}
          {index < 10 ? '0' : ''}
          {index}
        </h1>
        <h5 className="font-semibold text-slate-800 pb-2 md:pb-3 lg:pb-4">
          {title}
        </h5>
        <span className="text-slate-700 text-sm md:text-base 2xl:text-lg block">
          {desc}
        </span>
      </div>
    </article>
  )
}
