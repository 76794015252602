import React from 'react'
import emps from 'assets/emps.jpg'

import checkIcon from 'assets/home/checkIcon.svg'

export default function Features() {
  return (
    <section className="bg-slate-300 flex md:flex-row flex-col  gap-y-6 md:gap-3 min-h-[650px]">
      <div className="overflow-hidden flex-1 max-w-4xl min-h-[300px] h-[450px] lg:h-[650px]">
        <img
          src={emps}
          alt="snapshot of the processes followed by the shashi engineering"
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className=" blade-bottom-padding-lg blade-top-padding-sm pl-3 sm:pl-5 md:pl-10 lg:pl-24 xl:pl-32 2xl:pl-40 flex flex-col justify-center items-start gap-6 md:gap-8 lg:gap-10 flex-1">
        <h5 className="font-medium text-slate-900">
          What do we bring to the table?
        </h5>
        <ul className="flex flex-col gap-5 lg:pt-3">
          <li className="flex items-center gap-3 md:gap-4  text-base md:text-lg xl:text-xl font-normal leading-snug ">
            <img
              height={28}
              alt="check mark icons denoting the features in products of shashi engineering"
              aria-hidden
              width={28}
              className=" h-5 w-5 md:w-7 md:h-7 object-contain"
              src={checkIcon}
            />
            Uncompromising Quality Standards
          </li>
          <span className="pl-12">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Repellendus eligendi saepe atque ratione architecto maxime deserunt
            nam sapiente a, autem, quos quod laudantium eius voluptates?
            Praesentium, temporibus consectetur? Totam, quisquam error nihil
            incidunt consectetur repudiandae natus, eius aliquid hic, inventore
            laudantium ipsa soluta exercitationem quibusdam esse libero eaque.
            Omnis reprehenderit reiciendis, vel unde asperiores libero mollitia
            ex provident maxime at laborum molestias dolore ullam hic velit
            dolor error quibusdam accusamus tempora optio quia dignissimos?
            Porro laudantium assumenda libero tempora? Cupiditate eos, rerum
            placeat vel numquam error officia. Earum impedit eligendi quaerat,
            deleniti voluptatibus, odio, voluptates expedita velit nostrum rem
            iste!
          </span>
          <li className="flex items-center gap-3 md:gap-4  text-base md:text-lg xl:text-xl font-normal leading-snug ">
            <img
              height={28}
              alt="check mark icons denoting the features in products of shashi engineering"
              aria-hidden
              width={28}
              className=" h-5 w-5 md:w-7 md:h-7 object-contain"
              src={checkIcon}
            />
            ISO:9001 certification
          </li>
          <li className="flex items-center gap-3 md:gap-4  text-base md:text-lg xl:text-xl font-normal leading-snug ">
            <img
              height={28}
              alt="check mark icons denoting the features in products of shashi engineering"
              aria-hidden
              width={28}
              className=" h-5 w-5 md:w-7 md:h-7 object-contain"
              src={checkIcon}
            />
            Innovating with glass fiber
          </li>
          <li className="flex items-center gap-3 md:gap-4  text-base md:text-lg xl:text-xl font-normal leading-snug ">
            <img
              height={28}
              alt="check mark icons denoting the features in products of shashi engineering"
              aria-hidden
              width={28}
              className=" h-5 w-5 md:w-7 md:h-7 object-contain"
              src={checkIcon}
            />
            Global reach and excellence
          </li>
          <li className="flex items-center gap-3 md:gap-4  text-base md:text-lg xl:text-xl font-normal leading-snug ">
            <img
              height={28}
              alt="check mark icons denoting the features in products of shashi engineering"
              aria-hidden
              width={28}
              className=" h-5 w-5 md:w-7 md:h-7 object-contain"
              src={checkIcon}
            />
            Decades of engineering mastery
          </li>
          <li className="flex items-center gap-3 md:gap-4  text-base md:text-lg xl:text-xl font-normal leading-snug ">
            <img
              height={28}
              alt="check mark icons denoting the features in products of shashi engineering"
              aria-hidden
              width={28}
              className=" h-5 w-5 md:w-7 md:h-7 object-contain"
              src={checkIcon}
            />
            ISO:9001 certification
          </li>
          <li className="flex items-center gap-3 md:gap-4  text-base md:text-lg xl:text-xl font-normal leading-snug ">
            <img
              height={28}
              alt="check mark icons denoting the features in products of shashi engineering"
              aria-hidden
              width={28}
              className=" h-5 w-5 md:w-7 md:h-7 object-contain"
              src={checkIcon}
            />
            Innovating with glass fiber
          </li>
          <li className="flex items-center gap-3 md:gap-4  text-base md:text-lg xl:text-xl font-normal leading-snug ">
            <img
              height={28}
              alt="check mark icons denoting the features in products of shashi engineering"
              aria-hidden
              width={28}
              className=" h-5 w-5 md:w-7 md:h-7 object-contain"
              src={checkIcon}
            />
            Global reach and excellence
          </li>
        </ul>
      </div>
    </section>
  )
}
