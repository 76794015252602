import React from 'react'

interface Props {
  data: {
    title: string
    subtitle: Array<{
      weight: 'bold' | 'normal'
      size: 'normal' | 'large'
      value: string
    }>
    heroImage: string
  }
}

const sizeMap = {
  normal: 'text-base lg:text-lg  2xl:text-xl',
  large: 'text-lg lg:text-xl 2xl:text-2xl',
}

const weightMap = {
  bold: 'font-semibold',
  normal: 'font-normal',
}

export default function Banner({ data }: Props) {
  const { title, subtitle, heroImage } = data
  return (
    <section className="grid grid-cols-7 bg-gradient-to-b from-slate-300 via-slate-200 to-slate-300  products-banner">
      <div className="col-start-1 col-end-4 overflow-hidden">
        <img
          src={heroImage}
          alt={title}
          className="h-full w-full object-cover object-left-top"
          loading="eager"
        />
      </div>
      <div className="flex flex-col gap-8 lg:gap-10 2xl:gap-12 col-start-4 col-end-8 blade-top-padding-lg blade-bottom-padding-lg  md:w-[95%] lg:w-[85%] ml-auto  pr-4 md:pr-6 lg:pr-10">
        <h1 className="font-semibold w-11/12 blade-top-padding bg-gradient-to-b from-slate-800 via-slate-600 to-slate-800 bg-clip-text text-transparent  ">
          {title}
        </h1>
        <div>
          {subtitle.map((elem, index) => {
            const key = `${index}`
            const sizeClass = sizeMap[elem.size]
            const weightClass = weightMap[elem.weight]
            return (
              <span
                className={`${sizeClass} ${weightClass} leading-normal text-slate-800 w-10/12 pb-10 last:pb-0 block`}
              >
                {elem.value}
              </span>
            )
          })}
        </div>
      </div>
    </section>
  )
}
