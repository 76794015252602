import React, { useState } from 'react'

interface Props {
  data: {
    title: string
    pointers: Array<{
      coverImage: string
      title: string
      desc: string
    }>
    subtitle: string
  }
}

export default function About({ data }: Props) {
  const { title, subtitle, pointers } = data

  const [openIndex, setIndex] = useState(10)

  const toggleReadMore = (update: number) => {
    if (update === openIndex) setIndex(10)
    else setIndex(update)
  }

  return (
    <section className="blade-top-padding-lg  blade-bottom-padding-lg about-wrapper">
      <div className="grid place-content-center gap-2 text-center md:w-11/12 mx-auto px-5  blade-top-padding-sm blade-bottom-padding-sm ">
        <h2 className="font-semibold  text-slate-100 leading-tight md:block hidden">
          {title}
        </h2>
        <h5 className="text-slate-200">{subtitle} </h5>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-y-10 gap-x-6 xl:gap-x-10 w-container blade-bottom-padding">
        {pointers.map((elem, index) => {
          const { title, desc, coverImage } = elem
          return (
            <Card
              className=""
              index={index}
              openIndex={openIndex}
              handleToggle={toggleReadMore}
              title={title}
              desc={desc}
              cover={coverImage}
            />
          )
        })}
      </div>
    </section>
  )
}

function Card({
  title,
  cover,
  handleToggle,
  index,
  openIndex,
  className,
  desc,
}: {
  className: string
  index: number
  openIndex: number
  title: string
  handleToggle: (update: number) => void
  cover: string
  desc: string
}) {
  return (
    <article className={` w-full flex flex-col`}>
      <div className=" basis-[200px] rounded-t-lg lg:rounded-t-xl 2xl:rounded-t-[20px] salient-pointer-card-img lg:basis-[250px] xl:basis-[280px] 2xl:basis-[310px] flex-0 overflow-hidden ">
        <img
          src={cover}
          className="h-full w-full object-cover object-center"
          alt={title}
        />
      </div>
      <div
        className={` bg-slate-600  ${
          openIndex === index ? 'flex-1' : 'flex-0'
        }  salient-pointer-card-content rounded-b-lg lg:rounded-b-xl 2xl:rounded-b-[20px] overflow-hidden`}
      >
        <div className="pt-4 pb-6 md:py-4 2xl:py-6 px-2 lg:px-3 2xl:px-4">
          <h4 className="font-semibold leading-snug md:leading-tight pr-3 pb-2 md:pb-4 text-slate-100 ">
            {title}
          </h4>
          <div
            className={`  w-11/12 md:w-full ${className}  pb-2 leading-snug `}
          >
            <span
              className={` pr-3 text-sm md:text-base  inline text-slate-100 text-opacity-90`}
            >
              {openIndex === index ? desc : String(desc).slice(0, 110)}
              {index !== openIndex && String(desc).length > 120 ? '...' : ''}
            </span>
            {String(desc).length > 100 && (
              <button
                type="button"
                onClick={() => handleToggle(index)}
                className="text-sm md:text-base font-semibold text-blue focus-visible:text-blueDark focus-visible:underline underline-offset-4 decoration-from-font transition-colors duration-300 ease-in-out focus-visible:outline-none outline-none  hover:text-blueDark  "
              >
                Read {openIndex === index ? 'Less' : 'More'}
              </button>
            )}
          </div>
        </div>
      </div>
    </article>
  )
}
