import React from 'react'

const sizeMap = {
  small: 'text-sm',
  base: 'text-base px-5 py-3',
  large: ' text-base md:text-lg 2xl:text-xl  px-4 md:px-6 py-3 ',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}

type MasterBtnProps<T extends 'submit' | 'button'> = {
  text: string
  size: keyof typeof sizeMap
  type: T
  isDisabled?: boolean
  onClick?: T extends 'submit' ? never : () => void
}

const colorMap = {
  dark: 'text-slate-100 bg-slate-900',
  light: 'text-black text-opacity-90 bg-slate-100',
}

export function MasterBtn<T extends 'submit' | 'button'>({
  type,
  text,
  size,
  color = 'light',
  isDisabled,
  ...rest
}: MasterBtnProps<T> & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <button
      disabled={isDisabled}
      type={type === 'button' ? 'button' : 'submit'}
      {...rest}
      className={`${sizeClass}  disabled:opacity-70 disabled:pointer-events-none disabled:touch-none ${colorClass} group font-semibold stroke-slate-80 outline-none focus-visible:outline-none rounded-lg flex items-center gap-5 `}
    >
      {text}

      <div
        className={`grid  ${
          color === 'light' ? 'bg-slate-800 ' : 'bg-slate-100   '
        }  h-6 w-6 md:h-9 md:w-9 place-content-center p-1 rounded-full transition-colors duration-300 ease-in-out   group-hover:bg-orange-700 group-focus-visible:bg-orange-700`}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          className={` ${
            color === 'light' ? 'stroke-[#ebeff3]  ' : 'stroke-[#2a3440] '
          }  group-hover:stroke-white  transition-all duration-300 ease-in-out `}
          stroke="inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 7H13"
            stroke="inherit"
            strokeWidth="1.61044"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 1L13 7L7 13"
            stroke="inherit"
            strokeWidth="1.61044"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </button>
  )
}
