import React from 'react'
import { TextAnchor, Anchor, ExternalAnchor } from 'atoms/links'
import logoWhite from 'assets/logo.png'

export default function Footer() {
  return (
    <footer className="footer-gradient blade-top-padding blade-bottom-padding">
      <div className="w-container flex">
        <div className="basis-72 flex-0 ">
          <div className="pb-12">
            <img
              src={logoWhite}
              alt="logo for shashi engineering"
              className="h-28 w-auto object-contain object-center"
            />
          </div>

          <span className="md:block hidden text-sm 2xl:text-base  text-white text-opacity-70 font-normal whitespace-nowrap">
            ©2024. All Rights Reserved.
          </span>

          <div className="flex items-center pt-6">
            <ExternalAnchor
              name="click to visit the LinkedIn profile of Shashi Engineering"
              className="fill-white"
              href="https://www.linkedin.com/in/bhardwajsourabh/"
            >
              <svg
                stroke="inherit"
                fill="inherit"
                strokeWidth="0"
                viewBox="0 0 448 512"
                className="h-7 2xl:h-9 hover:fill-blue transition-all"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
              </svg>
            </ExternalAnchor>
          </div>
        </div>

        <div className="flex-1 flex justify-end ">
          <ul className="grid md:gap-3 gap-2  text-white list-none basis-60 grow-0 shrink 2xl:basis-80">
            <li className="2xl:text-lg font-semibold text-sm uppercase text-opacity-90">
              Products
            </li>

            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href=""
                text="Hydro generators"
              />
            </li>

            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href=""
                text="Industrial Motors"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href=""
                text="Traction Motors"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href=""
                text="Electrical Control Systems"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href=""
                text="Switchgear Control & Rectifiers"
              />
            </li>
          </ul>
          <ul className="grid md:gap-3 gap-2  text-white list-none basis-52 grow-0 shrink 2xl:basis-72">
            <li className="2xl:text-lg font-semibold text-sm uppercase text-opacity-90">
              Industries
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href="/solutions/product-development"
                text="Power Transmission"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href="/solutions/business-process-transformation"
                text="Locomotives"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href="/solutions/engineering-sustainability"
                text=" Hydro Power"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href="/solutions/business-innovation"
                text="Heavy Electricals"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href="/solutions/data-management"
                text="Bearings"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href="/solutions/data-management"
                text="Assemblies"
              />
            </li>
          </ul>
          <ul className="flex flex-col md:gap-3 gap-2  text-white list-none basis-56 grow-0 shrink 2xl:basis-64">
            <li className="2xl:text-lg font-semibold text-sm uppercase text-opacity-90">
              Company
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href="/solutions/product-development"
                text="About"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href="/solutions/business-process-transformation"
                text="Contact"
              />
            </li>
            <li>
              <TextAnchor
                color="light"
                size="small"
                className="text-white text-opacity-90 hover:underline focus-visible:underline focus-visible:outline-none outline-none  hover:text-opacity-100 underline-offset-2 decoration-from-font"
                href="/solutions/engineering-sustainability"
                text="Terms & Conditions"
              />
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

/*

 <div className="w-container 2xl:w-container-sm  flex justify-between ">
        <div className="flex-0">
          <img
            src="https://innogent.in/static/media/logo.2233544f79193726295d46ee7fa1feb3.svg"
            alt="logo for shashi engineering"
          />
        </div>
      </div>
      <div className=" w-container flex gap-3 md:flex-row flex-col justify-between items-center blade-top-padding-lg pb-3 mt-3">
        <h6 className="md:block hidden text-base 2xl:text-lg  text-white text-opacity-70 font-light whitespace-nowrap">
          ©2024. All Rights Reserved.
        </h6>

        <div className="flex items-center gap-4">
          <ExternalAnchor
            className="fill-white"
            href="https://www.linkedin.com/company/innogent-in"
          >
            <svg
              stroke="inherit"
              fill="inherit"
              strokeWidth="0"
              viewBox="0 0 448 512"
              className="h-7 2xl:h-9 hover:fill-blue transition-all"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
            </svg>
          </ExternalAnchor>
        </div>

        <h6 className="md:hidden block text-sm text-white whitespace-nowrap">
          ©2023. All Rights Reserved.
        </h6>
      </div>'
      
      */
