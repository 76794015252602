import React from 'react'
import sectionIcon from 'assets/about/leadership_icon.svg'
import profileImage from 'assets/about/profile.png'

export default function Leadership() {
  return (
    <section className="blade-top-padding-lg blade-bottom-padding-lg">
      <div className="grid place-content-center">
        <img
          src={sectionIcon}
          className="h-18 2xl:h-24 w-auto object-contain object-center"
          alt="icon denoting leadership of shashi engineering"
        />
      </div>
      <div className="grid gap-2 md:gap-3 text-center w-container pt-8">
        <h4 className="font-semibold text-slate-900 md:block hidden">
          Over 25 years of leadership experience
        </h4>

        <h3 className="font-semibold text-slate-900 md:hidden block leading-tight ">
          Over 25 years of <br />
          leadership experience
        </h3>
        <h6 className="font-normal text-slate-700">
          in matching requirements with excellence
        </h6>
      </div>

      <div className="grid  md:grid-cols-2 grid-cols-1 lg:grid-cols-3 w-container 2xl:w-container-sm gap-10 blade-top-padding-lg ">
        <ProfileCard
          title="Shashi Gupta"
          designation="CEO"
          profileImage={profileImage}
          profileURL="https://example.com"
        />

        <ProfileCard
          title="Shashi Gupta"
          designation="CEO"
          profileImage={profileImage}
          profileURL="https://example.com"
        />
      </div>
    </section>
  )
}

interface Props {
  title: string
  designation: string
  profileImage: string
  profileURL: string
}
function ProfileCard({ title, designation, profileImage, profileURL }: Props) {
  return (
    <article className="overflow-hidden rounded-lg h-96 2xl:h-[28rem] lg:h-[26rem] relative">
      <div className="absolute inset-0">
        <img
          src={profileImage}
          className="object-top object-cover h-full w-full"
          alt={`${title} - ${designation} at shashi engineering`}
        />
      </div>

      <div className="absolute inset-0 flex items-end  px-3 md:px-4  pb-3 md:pb-5">
        <div className="bg-orange-900 w-full rounded-lg p-3 flex flex-col gap-1">
          <h5 className="text-white font-semibold">{title}</h5>
          <small className="text-white font-normal text-opacity-80">
            {designation}
          </small>
        </div>
        <div>
          <a
            href={profileURL}
            className="rounded-full hover:bg-slate-100 bg-opacity-25 focus-visible:outline-none outline-none grid place-content-center p-1.5 focus-visible:bg-slate-100"
          >
            link
          </a>
        </div>
      </div>
    </article>
  )
}
