import React from 'react'
import mission_icon from 'assets/about/icons/mission.svg'
import vision_icon from 'assets/about/icons/vision.svg'
import guidelines_icon from 'assets/about/icons/guidelines.svg'

export default function MisionAndVision() {
  return (
    <section className="blade-top-padding-lg blade-bottom-padding-lg mission-wrapper ">
      <div className="text-center w-container">
        <h3 className="font-semibold text-slate-100 text-center leading-tight">
          Shaping the Future Through <br className="md:block hidden" />{' '}
          Innovation and Excellence
        </h3>
      </div>

      <div className="grid  grid-cols-1 md:grid-cols-2 w-container  blade-top-padding-lg  gap-y-10 lg:gap-7">
        <Card
          title="Mission"
          desc="Shashi Engineering aims to be a leading manufacturer of high-performance wooden patterns, FRP, and DMC components by offering exceptional value through innovative design, high-quality standards, and cost-effective solutions."
          thumb={mission_icon}
        />
        <Card
          title="Vision"
          desc="Shashi Engineering aspires to be a visionary leader within the industry, recognized for its Technical Pioneering, Unwavering Client Focus, Uncompromising Quality and Reliability and Operational Excellence"
          thumb={vision_icon}
        />
      </div>
    </section>
  )
}
interface Props {
  title: string
  desc: string
  thumb: string
}

function Card({ title, desc, thumb }: Props) {
  return (
    <article className="p-3">
      <div>
        <img
          src={thumb}
          alt={title}
          className="md:h-18  h-16 w-16 md:w-18 2xl:w-24 2xl:h-24 object-contain object-center"
        />
      </div>

      <div className="pt-8 lg:pt-10 flex flex-col gap-2 ">
        <h4 className="text-white font-semibold">{title}</h4>
        <h6
          dangerouslySetInnerHTML={{ __html: desc }}
          className="text-white text-opacity-90  w-11/12 md:w-10/12 "
        ></h6>
      </div>
    </article>
  )
}
