import {
  useState,
  useEffect,
  useReducer,
  MouseEventHandler,
  useRef,
} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import MobileNavModal from './mobileNav'
import { Popover, Transition as PopTransition } from '@headlessui/react'
import { MasterAnchor } from 'atoms/links'
import { Link } from 'react-router-dom'

import logoWhite from 'assets/logo_without_text.png'

const navClassName =
  ' text-base 2xl:text-lg font-normal leading-tight  hover:text-orange-400 focus-visible:text-orange-400 transition-colors duration-300 ease-in-out outline-none focus-visible:outline-none  text-white'

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false)
  const [mobileNav, toggleMobileNav] = useReducer((s) => !s, false)
  const [isOpen, toggleState] = useReducer((s) => !s, false)

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollY = window.scrollY
      if (scrollY > 10) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    })
  }, [])

  const industriesBtn = useRef<HTMLButtonElement>(null)
  const productBtn = useRef<HTMLButtonElement>(null)

  const navigate = useNavigate()

  const popNavigation = (target: string, popId: 'industry' | 'products') => {
    switch (popId) {
      case 'industry': {
        navigate(`/industries/${target}`)
        return industriesBtn.current?.click()
      }

      case 'products': {
        navigate(`/products/${target}`)
        return productBtn.current?.click()
      }
      default:
    }
  }

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [mobileNav])

  return (
    <>
      <header
        className={` ${
          isOpen ? 'border-b-1 border-white border-solid border-opacity-10' : ''
        } transition-colors fixed top-0 left-0 right-0 z-[999] duration-500 bg-gradient-to-b from-slate-800 to-slate-900 bg-opacity-80`}
      >
        <nav className="w-container-sm py-1 lg:py-0 flex items-center">
          <div className="basis-36 py-3 flex-0">
            <Link to="/">
              <img
                alt="logo of shashi engineering"
                src={logoWhite}
                className="h-10 md:h-14 lg:h-16  w-auto object-contain object-center"
              />
            </Link>
          </div>
          <ul className="flex  flex-1 items-center font-regular justify-end gap-6 md:gap-8  lg:gap-10 xl:gap-12 2xl:gap-16 list-none font-">
            <li className="lg:block hidden">
              <NavLink
                className={({ isActive }) =>
                  (isActive ? 'active' : '') + navClassName
                }
                to="about-us"
              >
                About Us
              </NavLink>
            </li>

            <li className="lg:block hidden">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      ref={industriesBtn}
                      className="text-base text-white flex flex-nowrap gap-1 2xl:text-lg items-center focus:outline-none font-normal  focus-visible:text-orange-400 hover:text-orange-400 hover:text-orange "
                    >
                      Industries
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="3.5"
                        stroke="currentColor"
                        className={` ${
                          open ? 'rotate-180' : ''
                        } transition-all duration-300 ease-in-out w-3 h-3 mt-[2px]`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </Popover.Button>

                    <PopTransition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      className="absolute top-20 md:top-20"
                    >
                      <Popover.Panel className="md:w-[18rem] p-3 rounded-md grid  gap-x-6 bg-white">
                        <div className="grow shrink grid   px-2 divide-y-1 divide-slate-200 gap-1">
                          <PopOption
                            callback={() =>
                              popNavigation('hydropower', 'industry')
                            }
                            text="Hydro Power"
                          />
                          <PopOption
                            callback={() =>
                              popNavigation('hydropower', 'industry')
                            }
                            text="Mechanical Engineering"
                          />

                          <PopOption
                            callback={() =>
                              popNavigation('mechanical', 'industry')
                            }
                            text="Hydro Power"
                          />
                          <PopOption
                            callback={() =>
                              popNavigation('hydropower', 'industry')
                            }
                            text="Mechanical Engineering"
                          />
                        </div>
                      </Popover.Panel>
                    </PopTransition>
                  </>
                )}
              </Popover>
            </li>

            <li className="lg:block hidden">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      ref={productBtn}
                      className="text-base text-white flex flex-nowrap gap-1 2xl:text-lg items-center focus:outline-none font-normal  focus-visible:text-orange-400 hover:text-orange-400 hover:text-orange "
                    >
                      Products
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="3.5"
                        stroke="currentColor"
                        className={` ${
                          open ? 'rotate-180' : ''
                        } transition-all duration-300 ease-in-out w-3 h-3 mt-[2px]`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </Popover.Button>

                    <PopTransition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      className="absolute top-20 md:top-20"
                    >
                      <Popover.Panel className="md:w-[18rem] p-3 rounded-md grid  gap-x-6 bg-white">
                        <div className="grow shrink grid   px-2 divide-y-1 divide-slate-200 gap-1">
                          <PopOption
                            callback={() =>
                              popNavigation('ballbearing', 'products')
                            }
                            text="Ball Bearing"
                          />
                          <PopOption
                            callback={() =>
                              popNavigation('rockerarmassembly', 'products')
                            }
                            text="Rocker Arm Assemblly"
                          />

                          <PopOption
                            callback={() => popNavigation('shafts', 'products')}
                            text="Shafts"
                          />
                          <PopOption
                            callback={() =>
                              popNavigation('hydropower', 'products')
                            }
                            text="Mechanical Engineering"
                          />
                        </div>
                      </Popover.Panel>
                    </PopTransition>
                  </>
                )}
              </Popover>
            </li>

            <li>
              <MasterAnchor
                size="small"
                color="light"
                text="Contact Us"
                href="/contact"
              />
            </li>

            <li className="lg:hidden block">
              <button onClick={toggleState} type="button">
                <svg
                  stroke="white"
                  fill="white"
                  stroke-width="0"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  className="h-6 my-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <div
        className={`${
          isOpen ? 'translate-y-0' : '-translate-y-full'
        } transition z-[997] duration-500 fixed inset-0 h-screen w-screen bg-black text-white`}
      >
        <MobileNavModal toggle={toggleState} />
      </div>
    </>
  )
}

function PopOption({
  callback,
  text,
  anchorLink,
}: {
  text: string
  callback: MouseEventHandler<HTMLButtonElement>
  anchorLink?: string
}) {
  return (
    <>
      {anchorLink ? (
        <a
          href={anchorLink}
          target="_blank"
          rel="noopener noreferrer"
          className="py-4 px-3  flex flex-nowrap items-center gap-1 group stroke-violet fill-violet font-semibold  outline-none focus:outline-none"
        >
          {text}
        </a>
      ) : (
        <button
          type="button"
          onClick={callback}
          className=" py-4 px-3  flex flex-nowrap items-center gap-1 group stroke-violet fill-violet font-semibold  outline-none focus:outline-none"
        >
          <span className="group-hover:text-violet group-focus:text-violet text-dark">
            {text}
          </span>
        </button>
      )}
    </>
  )
}
