import React, { DispatchWithoutAction, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logomark from 'assets/logo.png'
import { TextAnchor } from 'atoms/links'
import { CSSTransition } from 'react-transition-group'
import gsap from 'gsap'

export default function MobileNavModal({
  toggle,
}: {
  toggle: DispatchWithoutAction
}) {
  const navigate = useNavigate()
  const navigateTo = (target: string) => {
    navigate(target)
    toggle()
  }
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const dropdownRef = useRef<any>()

  const toggleDropdown = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  return (
    <section className=" h-full pt-24 bg-gradient-to-b  to-slate-800 from-slate-900 ">
      <nav className=" h-full">
        <section className="min-h-full text-dark overflow-auto flex flex-col flex-nowrap z-[999]">
          <div className="flex flex-col flex-1 pt-4">
            <ul className="list-none grid gap-3 ">
              <li onClick={toggle} className="px-3">
                <TextAnchor
                  size="base"
                  color="light"
                  text="Home"
                  href="/"
                  name="click to return back to home"
                  className="block py-2 text-white"
                />
              </li>
              <li
                className={`${
                  isOpenMenu ? 'bg-slate-900 py-4 ' : ''
                } px-3 transition-all duration-300 ease-in-out `}
              >
                <button
                  onClick={toggleDropdown}
                  type="button"
                  className={` ${
                    isOpenMenu ? 'pb-3 ' : ''
                  }  dropdown-toggle transition-all duration-300 ease-in-out  text-white border-none leading-none flex justify-between w-full items-center opacity-80 `}
                >
                  Solutions
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    className={`w-6 transition-all duration-500 
                              ${isOpenMenu ? '-rotate-90' : ''} `}
                  >
                    <path fill="none" d="M0 0h24v24H0V0z" />
                    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                  </svg>
                </button>

                <CSSTransition
                  in={isOpenMenu}
                  timeout={400}
                  unmountOnExit
                  onEnter={(node: any) =>
                    gsap.fromTo(
                      node,
                      { height: 0, opacity: -1 },
                      { height: 'auto', duration: 0.5, opacity: 1 },
                    )
                  }
                  onExiting={(node: any) =>
                    gsap.to(node, { height: 0, opacity: -1, duration: 0.5 })
                  }
                >
                  <div className="dropdown  overflow-hidden" ref={dropdownRef}>
                    <button
                      type="button"
                      onClick={() => setIsOpenMenu(false)}
                      className="flex flex-col gap-3 mt-2 pb-5 w-full"
                    >
                      <li onClick={toggle}>
                        <TextAnchor
                          color="light"
                          href="/solutions/product-development"
                          text="Product Development / Scaling"
                          className="whitespace-nowrap text-white"
                          size="small"
                        />
                      </li>

                      <li onClick={toggle}>
                        <TextAnchor
                          color="light"
                          href="/solutions/business-process-transformation"
                          text="Business Process Transformation"
                          className="whitespace-nowrap text-white"
                          size="small"
                        />
                      </li>

                      <li onClick={toggle}>
                        <TextAnchor
                          color="light"
                          href="/solutions/engineering-sustainability"
                          text="Engineering Sustainability"
                          className="whitespace-nowrap text-white"
                          size="small"
                        />
                      </li>

                      <li onClick={toggle}>
                        <TextAnchor
                          color="light"
                          href="/solutions/business-innovation"
                          text="Business Innovation"
                          className="whitespace-nowrap text-white"
                          size="small"
                        />
                      </li>

                      <li onClick={toggle}>
                        <TextAnchor
                          color="light"
                          href="/solutions/data-management"
                          text="Data Management"
                          className="whitespace-nowrap text-white"
                          size="small"
                        />
                      </li>
                    </button>
                  </div>
                </CSSTransition>
              </li>

              <li onClick={toggle} className="px-3">
                <TextAnchor
                  size="base"
                  color="light"
                  text="Services"
                  href="/services"
                  className="block py-2 text-white"
                />
              </li>
            </ul>
          </div>
        </section>
      </nav>
    </section>
  )
}
