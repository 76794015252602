import React, { useState } from 'react'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { MasterBtn } from 'atoms/buttons'
import emailIcon from 'assets/icons/email.svg'
import phoneIcon from 'assets/icons/phone.svg'
import locationIcon from 'assets/icons/location.svg'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type FormProps = {
  formTitle?: string
  withInfo?: boolean
}

const formSchema = z.object({
  name: z
    .string()
    .min(1, 'Name is required.')
    .max(30, 'Name can not be more than 30 characters.'),
  location: z.string().min(2, 'Please enter a valid location'),
  email: z
    .string()
    .email('Invalid email address.')
    .min(1, 'Email is required.'),
  phone: z.string().length(10, 'Phone number should be of 10 digits.'),
  message: z.string(),
})

export type FormFieldSchema = z.infer<typeof formSchema>

export default function Form({ formTitle = '', withInfo = true }: FormProps) {
  const [isLoading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormFieldSchema> = async (data) => {
    setLoading(true)
    emailjs
      .send('service_9tf0yss', 'template_rshj5s6', data, 'bl7V_PmgR6Yd4Ckwi')
      .then(
        () => {
          reset()
          toast('Form submitted succesfully!')
        },
        () => {
          toast('Could not submit the form')
        },
      )
      .finally(() => setLoading(false))
  }

  return (
    <>
      <ToastContainer position="top-right" theme="light" />

      <section className="blade-bottom-padding-sm">
        <section className="blade-top-padding blade-bottom-padding-lg">
          <div>
            {formTitle && (
              <div className="">
                <h3
                  className="font-semibold  mx-auto text-center leading-normal blade-bottom-padding-sm lg:block hidden text-slate-600"
                  dangerouslySetInnerHTML={{ __html: formTitle }}
                />
                <h4
                  className="font-bold mx-auto text-center leading-normal blade-bottom-padding-sm lg:hidden block max-w-sm w-11/12 capitalize"
                  dangerouslySetInnerHTML={{ __html: formTitle }}
                />
              </div>
            )}

            {withInfo && (
              <div className="grid  grid-cols-1 md:grid-cols-3  max-w-screen-xl gap-6 mx-auto md:w-11/12 md:px-6 px-3 lg:px-10 ">
                <article className="p-4 rounded-lg border-1 border-solid border-slate-200 bg-slate-100 flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    <img
                      src={emailIcon}
                      alt="email icon"
                      height={24}
                      width={24}
                      className="h-6 w-6 object-contain object-center"
                    />
                    <h6 className="font-semibold text-slate-900">Email</h6>
                  </div>

                  <div className="flex flex-col gap-1.5 flex-1 justify-end pb-1  items-start pt-4 ">
                    <a
                      href="mailto:shashiengg@yahoo.co.in"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      shashiengg@yahoo.co.in
                    </a>
                    <a
                      href="mailto:bhardwaj.vp@gmail.com"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      bhardwaj.vp@gmail.com
                    </a>
                  </div>
                </article>

                <article className="p-4 rounded-lg border-1 border-solid border-slate-200 bg-slate-100 flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    <img
                      src={phoneIcon}
                      alt="email icon"
                      height={24}
                      width={24}
                      className="h-6 w-6 object-contain object-center"
                    />
                    <h6 className="font-semibold text-slate-900">Phone</h6>
                  </div>

                  <div className="flex flex-col gap-1.5 items-start pt-4">
                    <a
                      href="tel:+919826467222"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      +91 982 646 7222
                    </a>
                    <a
                      href="tel:+919713440443"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      +91 971 344 0443
                    </a>
                  </div>

                  <div className="flex flex-col gap-1.5 items-start pt-2 justify-end pb-1">
                    <a
                      href="tel:+917554223791"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      +91 755 422 3791
                    </a>
                    <a
                      href="tel:+917552601513"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      +91 755 260 1513
                    </a>
                  </div>
                </article>

                <article className="p-4 rounded-lg border-1 border-solid border-slate-200 bg-slate-100 flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    <img
                      src={locationIcon}
                      alt="location icon"
                      height={24}
                      width={24}
                      className="h-6 w-6 object-contain object-center"
                    />
                    <h6 className="font-semibold text-slate-900">Location</h6>
                  </div>

                  <div className="flex flex-col gap-1.5 items-start pt-4  justify-end pb-1  flex-1 ">
                    <span className="text-base font-normal  underline-offset-2 decoration-from-font ">
                      171, Sector H, Govindpura Industrial Area, Bhopal, Madhya
                      Pradesh, 462023
                    </span>

                    <a
                      href="https://maps.app.goo.gl/Lf6cu3WWoHUAeoez6"
                      target="_blank"
                      className="text-base pt-3 underline font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      View on Google Maps
                    </a>
                  </div>
                </article>
              </div>
            )}
            <div className=" max-w-screen-xl mx-auto md:w-11/12 md:px-6 px-3 lg:px-10  ">
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                className=" flex flex-col  gap-4 md:gap-6  py-6 px-3 sm:p-5 md:p-8 lg:p-12 xl:p-16 2xl:p-20  border-1 border-solid rounded-lg  mt-6 border-slate-200 bg-slate-100"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="grid gap-1 text-base md:text-lg "
                    >
                      Name*
                      <input
                        className="bg-white text-base md:text-lg 2xl:text-xl font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg  "
                        id="name"
                        placeholder="Please enter your full name."
                        {...register('name')}
                      />
                      {errors.email && (
                        <span className="text-red font-medium text-sm lg:text-base leading-tight">
                          {errors.name?.message}
                        </span>
                      )}
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="grid gap-1 text-base md:text-lg "
                    >
                      Email Address*
                      <input
                        className="bg-white text-base md:text-lg 2xl:text-xl font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg  "
                        id="email"
                        type="email"
                        inputMode="email"
                        placeholder="Enter an active email address."
                        {...register('email')}
                      />
                      {errors.email && (
                        <span className="text-red font-medium text-sm lg:text-base leading-tight">
                          {errors.email?.message}
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="location"
                      className="grid gap-1 text-base md:text-lg "
                    >
                      Location*
                      <input
                        className="bg-white text-base md:text-lg 2xl:text-xl font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg  "
                        id="location"
                        type="location"
                        inputMode="text"
                        placeholder="Please enter your company's location."
                        {...register('location')}
                      />
                      {errors.location && (
                        <span className="text-red font-medium text-sm lg:text-base leading-tight">
                          {errors.location?.message}
                        </span>
                      )}
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="grid gap-1 text-base md:text-lg "
                    >
                      Contact Number*
                      <input
                        className="bg-white text-base md:text-lg 2xl:text-xl font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg  "
                        type="number"
                        id="phone"
                        inputMode="numeric"
                        placeholder="Please share your phone number."
                        {...register('phone')}
                      />
                      {errors.phone && (
                        <span className="text-red font-medium text-sm lg:text-base leading-tight">
                          {errors.phone?.message}
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Message
                    <textarea
                      rows={7}
                      cols={5}
                      id="message"
                      className="bg-white text-base md:text-lg 2xl:text-xl font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg   resize-y"
                      placeholder="Got something to say? We're all ears!"
                      {...register('message')}
                    />
                  </label>
                </div>
                <div className="grid place-content-end pt-2">
                  <MasterBtn
                    color="dark"
                    type="submit"
                    text={isLoading ? 'Loading...' : 'Submit'}
                    isDisabled={isLoading}
                    size="large"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}
