import { ContactForm } from 'organisms/index'
import React, { useLayoutEffect } from 'react'
import Banner from './banner'
import './contact.css'

export default function Pagecontact() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Banner />
      <div className="blade-top-padding-lg blade-bottom-padding ">
        <ContactForm />
      </div>
    </div>
  )
}
