import React, { useLayoutEffect } from 'react'
import { Banner, Features, About, Gallery } from '../shared'

export default function HydroGenerators() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Banner
        data={{
          title: 'Industrial Ball Bearings',
          subtitle: [
            {
              weight: 'bold',
              size: 'large',
              value:
                'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney',
            },

            {
              weight: 'normal',
              size: 'normal',
              value:
                'College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
            },
          ],
          heroImage:
            'https://image.lexica.art/full_webp/43dafcc7-c4d0-4d36-b6b5-b3473dae2a36',
        }}
      />

      <About
        data={{
          title: 'hello world',
          pointers: [
            {
              title: 'largest bearings in the world',
              desc: 'this bearing is the largest one in the entire world',
              coverImage:
                'https://images.unsplash.com/photo-1708649290066-5f617003b93f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              title: 'largest bearings in the world',
              desc: 'this bearing is the largest one in the entire world',
              coverImage:
                'https://images.unsplash.com/photo-1708649290066-5f617003b93f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              title: 'largest bearings in the world',
              desc: 'this bearing is the largest one in the entire world',
              coverImage:
                'https://images.unsplash.com/photo-1708649290066-5f617003b93f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
          ],
          subtitle:
            'This is a text which will be reflected in the final thing later on.',
        }}
      />

      <Features
        data={{
          title: 'list of the good features of this product',
          desc: 'here I will be listing all the features that I have on the product',
          coverImage:
            'https://unsplash.com/photos/a-mountain-covered-in-fog-and-low-lying-clouds-b9_Io4TCHz4',
          pointers: [
            {
              title: 'best quality industral bearings',
              desc: 'we have the best thing in the market',
            },
            {
              title: 'best quality industral bearings',
              desc: 'we have the best thing in the market',
            },
            {
              title: 'best quality industral bearings',
              desc: 'we have the best thing in the market',
            },
            {
              title: 'best quality industral bearings',
              desc: 'we have the best thing in the market',
            },
            {
              title: 'best quality industral bearings',
              desc: 'we have the best thing in the market',
            },
            {
              title: 'best quality industral bearings',
              desc: 'we have the best thing in the market',
            },
            {
              title: 'best quality industral bearings',
              desc: 'we have the best thing in the market',
            },
          ],
        }}
      />
      <Gallery
        title="Our range of deliverables"
        desc="this section will have the imaegs"
        images={[
          'https://unsplash.com/photos/a-mountain-covered-in-fog-and-low-lying-clouds-b9_Io4TCHz4',
        ]}
      />
    </div>
  )
}
