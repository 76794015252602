import React from 'react'
import { Outlet } from 'react-router-dom'
import {
  PageHome,
  PageError404,
  PageContact,
  PageTermsAndPolicies,
  PageAbout,
} from 'screens/index'

import {
  HydroGenerators,
  ElectricalCS,
  IndustrialMotors,
  SwitchGears,
} from 'screens/products'

import {
  HydroPower,
  ElectricalControls,
  PowerTransmission,
  Locomotives,
  HydroLabs,
} from 'screens/industries'

import { Header, Footer, Statistics, ContactForm } from 'organisms'

function ViewWithHeaderAndFooter({
  withCommonSections = false,
}: {
  withCommonSections?: boolean
}) {
  return (
    <div>
      <Header />
      <Outlet />
      {withCommonSections && (
        <>
          <Statistics />
          <ContactForm formTitle="Touch base with us" />
        </>
      )}
      <Footer />
    </div>
  )
}

export default function routes() {
  return [
    {
      path: '/',
      element: <ViewWithHeaderAndFooter />,
      children: [
        {
          path: '/',
          element: <PageHome />,
        },
        {
          path: '/contact',
          element: <PageContact />,
        },
        {
          path: '/about-us',
          element: <PageAbout />,
        },
        {
          path: '/about',
          element: <PageAbout />,
        },

        {
          path: '/terms-and-policies',
          element: <PageTermsAndPolicies />,
        },
        {
          path: '*',
          element: <PageError404 />,
        },
      ],
    },
    {
      path: '/products',
      element: <ViewWithHeaderAndFooter withCommonSections />,

      children: [
        {
          path: 'hydro-generators',
          element: <HydroGenerators />,
        },
        {
          path: 'electrical-control-switch',
          element: <ElectricalCS />,
        },
        {
          path: 'industrial-motors',
          element: <IndustrialMotors />,
        },
        {
          path: 'switch-gears',
          element: <SwitchGears />,
        },
      ],
    },
    {
      path: '/industries',
      element: <ViewWithHeaderAndFooter withCommonSections />,

      children: [
        {
          path: 'hydropower',
          element: <HydroPower />,
        },
        {
          path: 'power-transmission',
          element: <PowerTransmission />,
        },
        {
          path: 'locomotives',
          element: <Locomotives />,
        },
      ],
    },
  ]
}
