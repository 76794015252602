import React from 'react'

import dummyImage from 'assets/dummyImage.png'

export default function ProductsListing() {
  return (
    <section className="blade-top-padding-sm blade-bottom-padding">
      <div className="blade-top-padding-lg">
        <Card
          target="/products/slip-ring-assemblies"
          title="Slip Ring"
          features={[
            '<strong>Manufacturer:</strong> With over 20 years of experience, we are a top manufacturer of molded slip rings, slip ring assemblies, and open-type slip rings.',
            '<strong>Function:</strong> These components ensure smooth transmission of electrical power in electromechanical systems.',
            '<strong>Design and Construction:</strong> Our slip rings are built with high-grade materials and advanced techniques for durability and easy installation.',
            '<strong>Customization and Availability:</strong> We offer a wide range of slip rings in various specifications to meet global client needs.',
          ]}
          thumb={dummyImage}
        />
        <Card
          target="/products/slip-ring-assemblies"
          title="Carbon Brush Holder"
          features={[
            '<strong>Expertise:</strong> Leveraging extensive expertise, we offer premium electrical and motor carbon brush holders.',
            '<strong>Applications:</strong> Widely used in electric motors and machinery across various industries.',
            '<strong>Material and Manufacturing:</strong> Made from top-tier materials with advanced processes for easy installation, enhanced durability, and reliable performance.',
          ]}
          thumb={dummyImage}
        />

        <Card
          target="/products/slip-ring-assemblies"
          title="Electrical Insulator"
          features={[
            '<strong>Advanced Manufacturing:</strong> Our modern infrastructure enables us to produce top-quality electrical DMC insulators and insulator brass thimbles.',
            '<strong>Function:</strong> These insulators prevent unwanted current leakage, protecting electrical systems.',
            '<strong>Material Selection and Features:</strong> Made from high-grade materials with advanced techniques, our insulators offer easy installation, anti-corrosive properties, and robust designs for reliable performance.',
          ]}
          thumb={dummyImage}
        />
        <Card
          target="/products/slip-ring-assemblies"
          title="Terminal Plate"
          features={[
            '<strong>Established Reputation:</strong> Since 1996, we have built a strong reputation as a reliable supplier of high-quality DMC and electric motor terminal plates.',
            '<strong>Function:</strong> These terminal plates ensure secure and reliable wire connections in power distribution systems.',
            '<strong>Quality and Availability:</strong> Made with premium materials and advanced techniques, our terminal plates feature rugged designs, easy installation, excellent finishes, and competitive prices for various applications.',
          ]}
          thumb={dummyImage}
        />
        <Card
          target="/products/slip-ring-assemblies"
          title="Rocker Plate"
          features={[
            '<strong>Industry Leader:</strong> We are a recognized leader in supplying high-grade DC motor and DMC rocker plates.',
            '<strong>Applications:</strong> Essential components used in motors and related machinery within the electrical industry.',
            '<strong>Manufacturing and Features:</strong> Crafted from high-quality materials with advanced techniques, our rocker plates offer precise dimensions, exceptional durability, robust construction, and easy installation.',
          ]}
          thumb={dummyImage}
        />
        <Card
          subtitle="For Industrial Applications"
          target="/products/slip-ring-assemblies"
          title="Junction and Terminal Box"
          features={[
            '<strong>Function:</strong> Junction and terminal boxes are critical connection points in industrial electrical systems, providing:',
            '<strong>Environmental Protection:</strong> Shielding components from dust, moisture, and other elements that could affect functionality or safety.',
            '<strong>Organization and Accessibility:</strong> Facilitating organized wire grouping and termination for efficient maintenance and troubleshooting.',
            '<strong>Safety Enhancement:</strong> Minimizing the risk of accidental contact with live components, ensuring a safer working environment.',
          ]}
          thumb={dummyImage}
        />
        <Card
          target="/products/slip-ring-assemblies"
          subtitle="For Industrial Motors and Generators"
          title="Rocker Arm Assemblies"
          features={[
            '<strong>Function:</strong> Rocker arm assemblies play a critical role in efficient current transfer within industrial motors and generators by:',
            '<strong>Supporting Brush Holders:</strong> Providing a stable platform for brush holders, ensuring optimal positioning and pressure for effective current transfer.',
            '<strong>Maintaining Contact Pressure:</strong> Ensuring consistent pressure between brushes and the slip ring for continuous, reliable electrical contact.',
            '<strong>Enabling Current Collection:</strong> Maintaining proper contact pressure to efficiently collect current from the rotating slip ring by the stationary brushes.',
          ]}
          thumb={dummyImage}
        />
      </div>
    </section>
  )
}

interface Props {
  title: string
  features: Array<string>
  thumb: string
  target: string
  subtitle?: string
}

function Card({ title, features, target, thumb, subtitle }: Props) {
  return (
    <article className="blade-bottom-padding px-2">
      <div className="w-container 2xl:w-container-sm flex  gap-y-7  md:flex-row flex-col-reverse border-b-1 border-solid border-slate-300 pb-10 md:pb-16 lg:pb-20">
        <div className="flex-1 flex flex-col gap-3">
          <div className="pb-2 md:pb-4">
            <h3 className="font-semibold  ">{title}</h3>
            {subtitle && (
              <h6 className="text-neutral-700 font-semibold">{subtitle}</h6>
            )}
          </div>

          <ul className="flex flex-col gap-4 max-w-3xl">
            {features.map((elem, index: number) => {
              const key = `${index}`
              return (
                <li key={key} className=" flex items-start gap-3 ">
                  <svg
                    width={10}
                    height={11}
                    viewBox="0 0 10 11"
                    className=" h-3 w-2 md:h-7 md:w-4 min-w-[16px] mt-[2px]"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.1461 4.09549L2.442 0.22587C1.02244 -0.592749 -0.580064 0.970385 0.208451 2.40847L1.47285 4.72422C1.60277 4.96299 1.67084 5.23049 1.67084 5.50232C1.67084 5.77414 1.60277 6.04164 1.47285 6.28041L0.208451 8.59153C-0.580064 10.0296 1.02244 11.5927 2.442 10.7741L9.1461 6.9045C9.39284 6.76232 9.59776 6.55764 9.74025 6.31108C9.88273 6.06452 9.95775 5.78477 9.95775 5.5C9.95775 5.21523 9.88273 4.93548 9.74025 4.68892C9.59776 4.44236 9.39284 4.23768 9.1461 4.09549Z"
                      fill="#2A3440"
                      fillOpacity="0.7"
                    />
                  </svg>

                  <span
                    dangerouslySetInnerHTML={{ __html: elem }}
                    className="lg:text-lg 2xl:text-xl text-base md:text-base leading-tight  text-slate-600 pr-4 "
                  ></span>
                </li>
              )
            })}
          </ul>
        </div>

        <div className="rounded-lg min-h-[250px]  overflow-hidden  basis-64 md:basis-80 xl:basis-96 2xl:basis-[26rem] flex-0">
          <img
            src={thumb}
            className="h-full  scale-150 w-full object-cover object-center "
            alt={` ${title} - Product of Shashi Engineering`}
          />
        </div>
      </div>
    </article>
  )
}
