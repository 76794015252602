import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'

const slides = [
  {
    title: 'Online technical bulletin',
    desc: ' A technical topic related to interlining and its application is shared fortnightly with our business partners.',
    cover:
      'https://images.unsplash.com/photo-1706720093238-2324a804d12b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Shop floor studies',
    desc: 'Qualified textile technologists assess the relative productivity and performance of interlinings.',
    cover:
      'https://images.unsplash.com/photo-1706720093238-2324a804d12b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Technical seminars',
    desc: 'Our experts conduct educational seminars on the importance of using the right quality of interlining for different applications and fabrics.',
    cover:
      'https://images.unsplash.com/photo-1706720093238-2324a804d12b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Recommendation report',
    desc: 'An advisory report is developed for any type of fabric after technical analysis.',
    cover:
      'https://images.unsplash.com/photo-1706720093238-2324a804d12b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Online technical bulletin',
    desc: ' A technical topic related to interlining and its application is shared fortnightly with our business partners.',
    cover:
      'https://images.unsplash.com/photo-1706720093238-2324a804d12b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Shop floor studies',
    desc: 'Qualified textile technologists assess the relative productivity and performance of interlinings.',
    cover:
      'https://images.unsplash.com/photo-1706720093238-2324a804d12b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Technical seminars',
    desc: 'Our experts conduct educational seminars on the importance of using the right quality of interlining for different applications and fabrics.',
    cover:
      'https://images.unsplash.com/photo-1706720093238-2324a804d12b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: 'Recommendation report',
    desc: 'An advisory report is developed for any type of fabric after technical analysis.',
    cover:
      'https://images.unsplash.com/photo-1706720093238-2324a804d12b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
]

export default function Gallery({
  images,
  title,
  desc,
}: {
  title: string
  desc: string
  images: Array<string>
}) {
  return (
    <div className="product-features-carousel bg-gradient-to-b from-white to-slate-100 blade-bottom-padding-sm">
      <div className="grid gap-3  md:gap-4 lg:gap-5 text-center w-container  blade-top-padding-lg blade-bottom-padding-sm">
        <h3 className="text-slate-900 leading-tight font-medium">{title}</h3>
        <h6 className="text-slate-600">{desc}</h6>
      </div>
      <div className="blade-top-padding-sm gallery-carousel blade-bottom-padding-sm ">
        <Swiper
          centeredSlides
          slidesPerView={1.1}
          slidesPerGroup={1}
          loop
          navigation={{
            prevEl: '.life-carousel-prev-btn',
            nextEl: '.life-carousel-next-btn',
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            1024: {
              centeredSlides: false,
              slidesPerView: 2.5,
              spaceBetween: 24,
            },
            1280: {
              centeredSlides: false,
              spaceBetween: 24,
              slidesPerView: 3,
            },
            1440: {
              centeredSlides: false,
              slidesPerView: 2.6,
              spaceBetween: 24,
            },
          }}
          className="cursor-grab"
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={10000}
          modules={[Autoplay]}
          spaceBetween={6}
        >
          {slides.map((elem, index: number) => {
            const { cover, title, desc } = elem
            const key = `${index}`
            return (
              <SwiperSlide key={key}>
                <Slide title={title} desc={desc} cover={cover} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

function Slide({
  title,
  cover,
  desc,
}: {
  title: string
  desc: string
  cover: string
}) {
  return (
    <article className="2xl:rounded-[20px] group relative overflow-hidden">
      <div className="relative ">
        <div className="bg-gradient-to-b from-transparent to-black h-64 md:h-72 lg:h-80 xlg:h-[26rem] rounded-xl lg:rounded-2xl overflow-hidden  2xl:h-[28.75rem]">
          <img
            className=" h-64 md:h-72 lg:h-80 xlg:h-[26rem] 2xl:h-[28.75rem] w-full object-cover object-center"
            src={cover}
            alt={title}
          />
        </div>
      </div>
      <div className="h-16 group-hover:h-44 transition-all duration-300 ease-in-out bg-black bg-opacity-60 flex flex-col gap-2 pl-8 pr-10 pt-4 text-white absolute bottom-0 left-0 right-0 top-auto">
        <span className="text-2xl  font-semibold ">{title}</span>
        <span className="text-white text-opacity-90 text-base xl:text-lg leading-snug ">
          {desc}
        </span>
      </div>
    </article>
  )
}
