import { MasterAnchor } from 'atoms/links'
import React, { useLayoutEffect } from 'react'

export default function PageError404() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="bg-gradient-to-b min-h-screen  from-slate-300 via-slate-100 to-slate-300 flex flex-col items-center  justify-center">
      <div className="flex flex-col items-center justify-center gap-8 w-container ">
        <h1 className="  leading-tight text-[6rem] font-extrabold italic sm:text-[7rem] md:text-[9rem] lg:text-[11rem] xl:text-[14rem]">
          404
        </h1>
        <h4 className="font-semibold md:block hidden">
          Oops! Something is wrong.
        </h4>
        <h5 className="font-semibold md:hidden block">
          Oops! Something is wrong.
        </h5>

        <MasterAnchor
          name="click to return back to home"
          text="Return to home"
          color="light"
          size="base"
          href="/"
        />
      </div>
    </section>
  )
}
