import React from 'react'

interface Props {
  data: {
    title: string
    subtitle: Array<{
      weight: 'bold' | 'normal'
      size: 'normal' | 'large'
      value: string
    }>
    heroImage: string
  }
}

const sizeMap = {
  normal: 'text-base lg:text-lg  2xl:text-xl',
  large: 'text-lg lg:text-xl 2xl:text-2xl',
}

const weightMap = {
  bold: 'font-semibold',
  normal: 'font-normal',
}

export default function Banner({ data }: Props) {
  const { title, subtitle, heroImage } = data
  return (
    <section className="relative industries-banner  grid grid-cols-10 to-slate-300  products-banner">
      <div className="absolute inset-0">
        <img
          src={heroImage}
          alt={title}
          className="h-full w-full object-cover object-left-top"
          loading="eager"
        />
      </div>
      <div className="absolute inset-0 banner-overlay-gradient"></div>

      <div className=" col-start-2 col-end-11 h-full flex flex-col gap-5 items-start justify-end text-center relative blade-top-padding-lg blade-bottom-padding-lg">
        <h1 className="font-semibold blade-top-padding bg-gradient-to-b from-slate-100 via-white to-slate-100 bg-clip-text text-transparent  ">
          {title}
        </h1>
        <div className="blade-bottom-padding-sm ">
          {subtitle.map((elem, index) => {
            const key = `${index}`
            const sizeClass = sizeMap[elem.size]
            const weightClass = weightMap[elem.weight]
            return (
              <span
                className={`${sizeClass} ${weightClass}  leading-normal text-slate-200 w-full pb-10 last:pb-0 block`}
              >
                {elem.value}
              </span>
            )
          })}
        </div>
      </div>
    </section>
  )
}
