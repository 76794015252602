import React, { useLayoutEffect } from 'react'
import Banner from '../shared/banner'
import AboutIndustry from '../shared/about'
import Products from '../shared/products'

//  pointers: [
//   {
//     title: 'largest bearings in the world',
//     desc: 'this bearing is the largest one in the entire world',
//     coverImage:
//       'https://images.unsplash.com/photo-1708649290066-5f617003b93f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//   },
//   {
//     title: 'largest bearings in the world',
//     desc: 'this bearing is the largest one in the entire world',
//     coverImage:
//       'https://images.unsplash.com/photo-1708649290066-5f617003b93f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//   },
//   {
//     title: 'largest bearings in the world',
//     desc: 'this bearing is the largest one in the entire world',
//     coverImage:
//       'https://images.unsplash.com/photo-1708649290066-5f617003b93f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//   },
// ],

export default function Locomotives() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Banner
        data={{
          title: 'making waves in the automobile thing',
          heroImage:
            ' https://images.unsplash.com/photo-1708669101365-1a9e3e834ee7?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
          subtitle: [
            {
              size: 'normal',
              value: 'this is the value',
              weight: 'normal',
            },
          ],
        }}
      />

      <Products
        title="range of products we have"
        subtitle="list of all the products we have in this"
        products={[
          {
            title: 'sample product',
            coverImage:
              'https://images.unsplash.com/photo-1708958485683-85ef0ecd24ff?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxNHx8fGVufDB8fHx8fA%3D%3D',
            speficationFile: 'some file',
            subtitle:
              'this is something I will be adding later on and this is the thing which I have been working on',
          },
          {
            title: 'sample product',
            coverImage:
              'https://images.unsplash.com/photo-1708958485683-85ef0ecd24ff?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxNHx8fGVufDB8fHx8fA%3D%3D',
            speficationFile: 'some file',
            subtitle:
              'this is something I will be adding later on and this is the thing which I have been working on',
          },
        ]}
      />
    </div>
  )
}
