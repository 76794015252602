import React, { useLayoutEffect } from 'react'
import Banner from './banner'
import Clientele from './clientele'
import SubFooter from 'organisms/subFooter'
import './home.css'
import Features from './features'
import ProductsListing from './productsListing'
import Statistics from 'organisms/statistics'
import { ContactForm } from 'organisms/index'
import AboutUs from 'screens/about/about_us'

export default function PageHome() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Banner />
      <Clientele />
      <AboutUs />
      <Features />
      <Statistics />
      <ProductsListing />
      <ContactForm formTitle="Talk to our experts" withInfo />

      <div className="h-44 "></div>
    </div>
  )
}
