import React, { useLayoutEffect } from 'react'
import './about.css'
import Banner from './banner'
import Leadership from './leadership'
import AboutUs from './about_us'
import MisionAndVision from './mission_vision'
import { ContactForm, Statistics } from 'organisms/index'

export default function PageAbout() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Banner />
      <AboutUs />
      <Leadership />
      <MisionAndVision />
      <ContactForm />
    </div>
  )
}
