import React from 'react'

export default function AboutUs() {
  return (
    <section className="blade-top-padding-lg blade-bottom-padding-lg bg-gradient-to-b from-white to-slate-100">
      <div className="grid grid-cols-11 px-4">
        <div className="col-start-1 md:col-start-2 col-end-12 max-w-7xl">
          <h6 className="text-slate-700">
            Established Authority in Electro-Mechanical Design and Manufacturing
          </h6>
          <h4 className="text-slate-900 font-medium w-11/12 leading-normal pt-4">
            For over 25 years, Shashi Engineering has built a distinguished
            reputation as a leading provider of electro-mechanical solutions. We
            remain firmly committed to technical innovation and operational
            excellence, ensuring our clients receive the most advanced and
            reliable products in the industry. Our core strength lies in the
            ability to deliver not only high-precision, custom-designed
            components but also bespoke machinery tailored to the diverse
            requirements of a wide range of industries.
          </h4>
        </div>
      </div>
    </section>
  )
}
