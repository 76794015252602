import React from 'react'
import customers from 'assets/globals/stats_icons/customers.svg'
import products from 'assets/globals/stats_icons/products.svg'
import years from 'assets/globals/stats_icons/years.svg'

export default function Statistics() {
  return (
    <div className="statistics-wrapper blade-top-padding-lg blade-bottom-padding-lg px-3 ">
      <div className="w-container grid py-16 md:py-6 lg:py-2 grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-4 lg:gap-6  md:w-10/12 bg-slate-100 rounded-2xl border-2 border-solid border-slate-200  blade-top-padding-sm blade-bottom-padding-sm lg:w-9/12 ">
        <Card icon={products} value="250+" desc="Products" />
        <Card icon={customers} value="35+" desc="Customers" />
        <Card icon={years} value="26+" desc="Years in business" />
      </div>
    </div>
  )
}

function Card({ desc, value, icon }: any) {
  return (
    <article className="px-7 py-10">
      <div className="grid place-content-center place-items-center p-2">
        <img
          src={icon}
          className=" h-20 lg:h-24 xl:h-28 w-auto object-contain object-center"
          alt={`${value} ${desc}`}
        />
      </div>
      <div className="grid place-content-center text-center pt-3 ">
        <h3 className="font-extrabold italic text-orange-700 md:block hidden">
          {value}
        </h3>
        <h2 className="font-extrabold italic text-orange-700 md:hidden block">
          {value}
        </h2>

        <h5 className="font-semibold leading-tight text-center p-2">{desc}</h5>
      </div>
    </article>
  )
}
