import React from 'react'
import bhel from 'assets/home/clients/bhel.png'
import nhpc from 'assets/home/clients/nhpc.png'
import railways from 'assets/home/clients/railways.svg'
import kseb from 'assets/home/clients/kseb.png'
import bhilwara from 'assets/home/clients/bhilwara.png'
import tata_power from 'assets/home/clients/tata_power.png'
import drdo from 'assets/home/clients/drdo.png'
import snvl from 'assets/home/clients/snvl.png'
import essar from 'assets/home/clients/essar.png'

const clients = [
  bhel,
  nhpc,
  railways,
  kseb,
  bhilwara,
  tata_power,
  drdo,
  snvl,
  // essar,
]

export default function Clientele() {
  return (
    <div className="blade-top-padding blade-bottom-padding bg-slate-100">
      <div className="w-container  text-center">
        <h6 className="font-semibold blade-bottom-padding text-slate-900 leading-tight px-3">
          A Trusted Partner Across Industries
        </h6>
        <div className="grid grid-cols-2 blade-bottom-padding md:grid-cols-4 max-w-4xl mx-auto  gap-x-6 gap-y-10 md:gap-x-5 md:gap-y-20">
          {clients.map((elem, index) => {
            const key = `${index}`
            return (
              <div key={key} className="p-1 grid place-content-center">
                <img
                  className="h-24 2xl:h-28 w-auto object-contain object-center"
                  src={elem}
                  alt="bhel- a client of shasi engineering"
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
