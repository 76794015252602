import React, { useState } from 'react'

interface Props {
  title: string
  subtitle: string

  products: Array<{
    title: string
    subtitle: string
    coverImage: string
    speficationFile: string
  }>
}
export default function Products({ title, subtitle, products }: Props) {
  const [openIndex, setIndex] = useState(10)

  const toggleReadMore = (update: number) => {
    if (update === openIndex) setIndex(10)
    else setIndex(update)
  }

  return (
    <section className="blade-top-padding-lg blade-bottom-padding-lg bg-slate-200 ">
      <div className="flex items-center gap-3 justify-center text-center w-container pt-2 md:pt-3 lg:pt-4">
        <div className="blade-bottom-padding-sm grid  gap-3 md:gap-4 ">
          <h3
            className="font-semibold  mx-auto text-center leading-normal  lg:block hidden text-slate-800"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <h4
            className="font-bold mx-auto text-slate-800 text-center leading-normal  lg:hidden block max-w-sm w-11/12 capitalize"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <h6 className="font-normal text-slate-600">{subtitle}</h6>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-y-10 gap-x-6 xl:gap-x-10 w-container blade-bottom-padding pt-4">
        {products.map((elem, index) => {
          const { title, subtitle, coverImage, speficationFile } = elem
          return (
            <Card
              className=""
              index={index}
              openIndex={openIndex}
              handleToggle={toggleReadMore}
              title={title}
              desc={subtitle}
              cover={coverImage}
            />
          )
        })}
      </div>
    </section>
  )
}

function Card({
  title,
  cover,
  handleToggle,
  index,
  openIndex,
  className,
  desc,
}: {
  className: string
  index: number
  openIndex: number
  title: string
  handleToggle: (update: number) => void
  cover: string
  desc: string
}) {
  return (
    <article className={` w-full flex flex-col`}>
      <div className=" relative  basis-[200px] rounded-t-lg lg:rounded-t-xl 2xl:rounded-t-[20px] salient-pointer-card-img lg:basis-[250px] xl:basis-[280px] 2xl:basis-[310px] flex-0 overflow-hidden ">
        <img
          src={cover}
          className="h-full w-full object-cover object-center"
          alt={title}
        />
        <div className="absolute inset-0 grid place-content-end pb-3 pr-3">
          <button className="bg-slate-100 font-medium rounded-full px-4 py-1.5 flex items-center justify-center gap-1.5 outline-none focus-visible:outline-none  focus-visible:bg-slate-800 focus-visible:text-slate-100 hover:text-slate-100 hover:bg-slate-800 transition-all duration-300 ease-in-out group">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                className="group-hover:fill-white focus-visible:fill-white transition-all duration-300 ease-in-out"
              >
                <path
                  fill="inherit"
                  d="M12 4a1 1 0 0 0-1 1v9.529l-4.218-4.223a1.043 1.043 0 0 0-1.476 0 1.046 1.046 0 0 0 0 1.478l5.904 5.91c.217.217.506.319.79.305.284.014.573-.088.79-.305l5.904-5.91a1.046 1.046 0 0 0 0-1.478 1.043 1.043 0 0 0-1.476 0L13 14.529V5a1 1 0 0 0-1-1zM5 21a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1z"
                ></path>
              </svg>
            </div>
            Specifications
          </button>
        </div>
      </div>
      <div
        className={` bg-slate-100  ${
          openIndex === index ? 'flex-1' : 'flex-0'
        }  salient-pointer-card-content rounded-b-lg lg:rounded-b-xl 2xl:rounded-b-[20px] overflow-hidden`}
      >
        <div className="pt-4 pb-6 md:py-4 2xl:py-6 px-2 lg:px-3 2xl:px-4">
          <h4 className="font-semibold leading-snug md:leading-tight pr-3 pb-2 md:pb-4 text-slate-700 ">
            {title}
          </h4>
          <div
            className={`  w-11/12 md:w-full ${className}  pb-2 leading-snug `}
          >
            <span
              className={` pr-3 text-base md:text-lg inline text-slate-600 text-opacity-90`}
            >
              {openIndex === index ? desc : String(desc).slice(0, 110)}
              {index !== openIndex && String(desc).length > 120 ? '...' : ''}
            </span>
            {String(desc).length > 100 && (
              <button
                type="button"
                onClick={() => handleToggle(index)}
                className="text-sm md:text-base font-semibold text-blue focus-visible:text-blueDark focus-visible:underline underline-offset-4 decoration-from-font transition-colors duration-300 ease-in-out focus-visible:outline-none outline-none  hover:text-blueDark  "
              >
                Read {openIndex === index ? 'Less' : 'More'}
              </button>
            )}
          </div>
        </div>
      </div>
    </article>
  )
}
