import React from 'react'

export default function Banner() {
  return (
    <div className="min-h-[500px]  relative flex items-center justify-center blade-top-padding pb-5  contact-banner bg-[url('assets/contact/banner.png')] bg-cover bg-center bg-no-repeat">
      <div className="absolute inset-0 bg-white bg-opacity-70" />
      <div className="  grid gap-4 text-center w-container relative">
        <h2 className="title w-full bg-clip-text font-semibold text-center text-transparent">
          Touch base with us!
        </h2>
        <h6 className="text-center text-slate-800 text-opacity-80 leading-tight font-medium">
          We welcome your enquiries. Our team will be happy to help you.
        </h6>
      </div>
    </div>
  )
}
