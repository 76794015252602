import React from 'react'
import bannerImage from 'assets/about/banner.png'

export default function Banner() {
  return (
    <section className="about-banner blade-top-padding-lg blade-bottom-padding-lg relative  flex items-end">
      <div className="absolute inset-0">
        <img
          src={bannerImage}
          alt="background image for banner"
          className="h-full w-full object-cover object-right mix-blend-luminosity grayscale"
        />
      </div>

      <div className="grid grid-cols-7 px-4">
        <div className="col-start-1 md:col-start-2 col-end-8 relative flex flex-col blade-bottom-padding-sm justify-end  gap-3 lg:gap-6 h-full">
          <h6 className=" text-slate-600 text-opacity-80 leading-tight font-medium fade-in  animate-appear opacity-0">
            Experience, Quality and Innovation
          </h6>
          <h2 className="text-slate-800 bg-clip-text font-semibold appear-with-delay md:block hidden  animate-appear-with-delay opacity-0">
            Blending a unique combination technical{' '}
            <br className="md:block hidden" />
            expertise, Mechanical Proficiency, and{' '}
            <br className="md:block hidden" /> ethical leadership since 1996
          </h2>

          <h3 className="text-slate-800 bg-clip-text font-semibold appear-with-delay md:hidden block pr-3 animate-appear-with-delay opacity-0">
            Blending a unique combination technical{' '}
            <br className="md:block hidden" />
            expertise, Mechanical Proficiency, and{' '}
            <br className="md:block hidden" /> ethical leadership since 1996
          </h3>
        </div>
      </div>
    </section>
  )
}
